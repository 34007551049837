<template>
  <section>
  <NavigationHeaderVue class="mb-2" :componentActive="urlProps"/>
  <!-- <Nav_StatusHeader :componentActive="urlProps" /> -->
  <main>
    <router-view/>
    <!-- <ShoppingCart :modelValue="isPlaceCheckout" v-if="isPlaceCheckout"/> -->
  </main>
  <footer class="ezGolf-footer pt-2 mt-3">
    <p class="clearfix mb-0">
      <span v-html="footer">
      </span>
      <span class="float-md-left d-block d-md-inline-block mt-25 m-lg-2">
        Powered by ezCloud
      </span>
    </p>
    </footer>
</section>
</template>
<script lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from '@/pinia/store';
import { useRoute } from 'vue-router';
import NavigationHeaderVue from './components/NavigationHeader CMG/NavigationHeader.vue';
// import ShoppingCart from './components/ShoppingCart/ShoppingCart.vue';
// import Nav_StatusHeader from '@/components/Nav_StatusHeader/Nav_StatusHeader.vue'
export default {
  components: {
    NavigationHeaderVue,
    // Nav_StatusHeader
    // ShoppingCart,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isPlaceCheckout = ref<boolean>(false);
    const hasLogin = computed<boolean>(() => store.$state.hasLogin);
    const urlProps = ref<string>('')
    const footer = localStorage.getItem('BoFooter')

    watch(() => route.fullPath, (value: string) => {
      urlProps.value = value ? value : ''
      // isPlaceCheckout.value = value.search('checkout') > -1 ? false : true;
    });

    onMounted(() => {
      document.title = localStorage.getItem('SiteName') || ''
    });

    

    return {
      hasLogin,
      isPlaceCheckout,
      route,
      urlProps,
      footer
    };
  }
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.btn {
  border-radius: 8px !important;
}
</style>
