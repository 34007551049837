import router from '@/router/index';
import {notify} from '@/mixin'
import { toast, type ToastOptions } from 'vue3-toastify';

// eslint-disable-next-line
export const successReponse = async (res: any) => {
        if (res.data.Status === '400') {
            notify(res.data)
            if(res.data.Messages[0].Code.includes("C401") ) {
                location.reload()
                return;
            }
        } else if(res.data.Status === '500') {
            if(localStorage.getItem('Lang') == '1000000') {
                toast.error('Hệ thống có sự cố, vui lòng thử lại', {
                    autoClose: 5000,
                    position: toast.POSITION.TOP_RIGHT,
                } as ToastOptions);
            } else {
                toast.error('There is a problem with the system, please try again', {
                    autoClose: 5000,
                    position: toast.POSITION.TOP_RIGHT,
                } as ToastOptions);
            }
        }
        return res;
}