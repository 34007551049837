import { ref } from 'vue';
import { defineStore } from "pinia";
import { ProfileUser, DataConfirmDialog, DataSaveShoppingCart } from '@/type/type';
import useIndexDB from '@/composables/helper/indexDB';


const { storage } = useIndexDB();
const item = ref<string[]>([]);
const infoUser = ref<ProfileUser | null>(null);
const hasLogin = ref<boolean>(false);
const dataConfrimDialog = ref<DataConfirmDialog>({
    open: false,
    name: '',
    message: '',
    data: null
});
const hasItemBookingCart = ref<number>(0);

export const useStore = defineStore({
    id: 'store',
    state: () =>({
        item,
        infoUser,
        hasLogin,
        dataConfrimDialog,
        hasItemBookingCart,
    }),
    actions: {
        addItem(val: string) {
            item.value.push(val);
        },
        addInfoUser(val: ProfileUser) {
            infoUser.value = val;
        },
        setStatesLogin(val: boolean) {
            hasLogin.value = val;
        },
        showDialog(val: DataConfirmDialog) {
            dataConfrimDialog.value = val;
        },
        async checkAndShowButtonBookingCart(val: boolean) {
            let numberItem = 0;
            if (val) {
                const dataCartIndexDb = await storage.getAll('cart') as DataSaveShoppingCart[];
                numberItem = dataCartIndexDb.length > 0 ? dataCartIndexDb.length : 0;
            }
            hasItemBookingCart.value = numberItem;
        }
    }
});