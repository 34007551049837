<template>
    <div class="dashboard-tab">
            <div class="container-v2">
                <div class="dashboard-tab__list">
                    <a href="" onclick="return false" class="">
                        <button class="after-button status-select-teetime ">
                            <ez-icon
                                icon="ezGolf-icon-golf-course"
                                :size="18"
                                style="margin-right:5px"
                            />
                            {{ $t('bo_step_select_tee_time') }}
                        </button>
                    </a>
                    <a href="" onclick="return false">
                        <button class="after-button status-payment">
                            <ez-icon
                                icon="ezGolf-icon-wallet"
                                :size="18"
                                style="margin-right:5px"
                            />
                            <span v-if="AllowBoPaymentOnline == 'ON'">{{ $t('bo_step_payment') }}</span>
                            <span v-else>{{ $t('bo_step_confirm') }}</span>
                        </button>
                    </a>
                    <a href="" onclick="return false">
                        <button class="status-success">
                            <ez-icon
                                icon="ezGolf-icon-check-circle"
                                :size="18"
                                style="margin-right:5px"
                            />
                            <span>{{ $t('bo_step_result') }}</span>
                        </button>
                    </a>
                </div>

            </div>
        </div>
</template>
<script lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
type Prop = {
    componentActive: string;
}
export default {
    props: {
        componentActive: {
            type: String,
            required: true,
            default: '',
        }
    },
    setup(props: any) {
        // onMounted(() => {
        //     console.log(props)
        // })
        const AllowBoPaymentOnline = localStorage.getItem('AllowBoPaymentOnline')
        watch(props, () => {
            if(props.componentActive.includes('/booking-golf')) {
                const dt = document.getElementsByClassName('status-select-teetime') 
                dt[0].classList.remove('hide-status')
                dt[0].classList.add('active')
                const dt1 = document.getElementsByClassName('status-payment') 
                dt1[0].classList.remove('active')
                dt1[0].classList.add('hide-status')
                const dt2 = document.getElementsByClassName('status-success') 
                dt2[0].classList.remove('active')
                dt2[0].classList.add('hide-status')
            } else if(props.componentActive.includes('/payment-booking')) {
                const dt = document.getElementsByClassName('status-select-teetime') 
                dt[0].classList.remove('active')
                dt[0].classList.add('hide-status')
                const dt1 = document.getElementsByClassName('status-payment') 
                dt1[0].classList.add('active')
                dt1[0].classList.remove('hide-status')
                const dt2 = document.getElementsByClassName('status-success') 
                dt2[0].classList.remove('active')
                dt2[0].classList.add('hide-status')
            } else if (props.componentActive.includes('/result')) {
                const dt = document.getElementsByClassName('status-select-teetime') 
                dt[0].classList.remove('active')
                dt[0].classList.add('hide-status')
                const dt1 = document.getElementsByClassName('status-payment') 
                dt1[0].classList.remove('active')
                dt1[0].classList.add('hide-status')
                const dt2 = document.getElementsByClassName('status-success') 
                dt2[0].classList.add('active')
                dt2[0].classList.remove('hide-status')
            }
        })
        return {
            AllowBoPaymentOnline
        }
    }
}
</script>
<style lang="scss">
.dashboard-tab {
    background: none;
    padding: 12px 0;
    .container-v2 {
        max-width: 1400px;
        margin: 0 auto;
        width: auto;
        .dashboard-tab__list {
            display: flex;
            align-items: end;
            text-align: center;
            padding: 0 150px;
             a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc(100% / 3);
                color: #007bff;
                text-decoration: none;
                background-color: transparent;
                outline: none;
                cursor: pointer;
                button {
                    color: #fff;
                    background: transparent;
                    border: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    text-transform: none;
                    overflow: visible;
                    border-radius: 0;
                    outline: none;
                    cursor: pointer;
                }
                // .after-button::after {
                //     content: "";
                //     width: 60px;
                //     height: 2px;
                //     background: #fff;
                //     position: absolute;
                //     top: 50%;
                //     left: 150%;
                //     opacity: 1;
                // }
                
            }
        }
    }
   
}
@media only screen and (max-width: 1400px) {
    .container-v2 { 
        max-width: 100% !important;
    }
  .hide-status {
    display: none !important;
  }
  .after-button::after {
    display: none;
  }
  .dashboard-tab__list {
    flex-direction: column;
    padding: 0 !important;
    a {
        width: 100% !important;
    }
  }
}
@media(max-width: 450px) {
.dashboard-tab {
    padding: 0 !important;
}
}
</style>