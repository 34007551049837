<template>
    <b-nav class="nav-header w-100 d-flex py-3 ezGolf-nav" :vertical="true" pills>
      <b-row class="w-100 d-flex justify-content-between" style="padding-left: 5%;">
        <div @click="returnHome()" class="header_logo">
            <img :src="urlImg" class="nav_img" alt="" />
            <h2 class="site_name">
              {{ SiteName }}
            </h2>
        </div>
        <div class="header_action">
            <Nav_StatusHeader :componentActive="componentActive" />
        </div>
      </b-row>
    </b-nav>
  </template>
  <script  lang="ts">
import Nav_StatusHeader from '@/components/Nav_StatusHeader/Nav_StatusHeader.vue'
  import { ref, onMounted} from 'vue';
  import router from '@/router/index';
    export default {
      components: {
        Nav_StatusHeader
      },
      props: {
        componentActive: {
            type: String,
            required: true,
            default: '',
        }
    },
      setup() {
        const route = router;
        const SiteName = ref<string>('')
        const urlImg = ref<string>('')
        const returnHome = () => {
          if(localStorage.getItem('Lang') == '1000001') {
            route.push({path: `/booking-golf/en`})
          } else {
            route.push({path: `/booking-golf/vi`})
          }
        }
          onMounted( () => {
            SiteName.value = localStorage.getItem('SiteName') || ''
            urlImg.value = `/${localStorage.getItem('ImageFolder')}/logo.png`
          })
          return {
            SiteName,
            urlImg,
            returnHome
          }
      }
    }
</script>
  <style lang="scss" scoped>
  @import "./NavigationHeader.scss";
  </style>
  