<template>
  <i
    :class="'ez-icon ' + icon"
    :style="'font-size:' + size + 'px; color:' + color"
    @click="$emit('click', $event);"
  />
</template>

<style lang="scss" scoped>
.ez-icon {
    vertical-align: middle;
    &:before {
        color: currentColor;
    }
}
</style>
<script>
export default {
  name: 'EzIcon',

  props: {
    icon: {
      type: String,
      default: 'ezGolf-icon-golf-tee',
    },
    size: {
      type: Number,
      default: 13,
    },
    color: {
      type: String,
    },
  },
}
</script>
