import dayjs from 'dayjs'
import * as CryptoJS from 'crypto-js'
import { toast, type ToastOptions } from 'vue3-toastify';

export const convertLocalDateToTimeHotel = (value: any) => {
    const timeZone = Number(localStorage.getItem('TimeZoneOffset'))
    return dayjs(value).add(-timeZone, 'minutes').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
}

export const convertUTC = (value: any, format: string) => {
    return dayjs(value).format(format)
}
export const convertLocalDateToTimeHoteltoString = (value: any) => {
    const timeZone = Number(localStorage.getItem('TimeZoneOffset'))
    return dayjs(value).add(-timeZone, 'minutes').format('YYYYMMDD')
}
export function EncryptData(data: string[]) {
    const hash = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(data.join('|')))
    return hash.toString(CryptoJS.enc.Base64)
}
export function notify(data: any){
    if(data.Status == 200) {
        if(localStorage.getItem('Lang') == '1000000') {
            toast.success('Thành công', {
                autoClose: 5000,
                position: toast.POSITION.TOP_RIGHT,
            } as ToastOptions);
        } else {
            toast.success('Success', {
                autoClose: 5000,
                position: toast.POSITION.TOP_RIGHT,
            } as ToastOptions);
        }
    } else if(data.Status == 400) {
        toast.error( data.Messages[0].MessageText , {
            autoClose: 5000,
            position: toast.POSITION.TOP_RIGHT,
        } as ToastOptions)
    }
}