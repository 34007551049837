import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dashboard-tab" }
const _hoisted_2 = { class: "container-v2" }
const _hoisted_3 = { class: "dashboard-tab__list" }
const _hoisted_4 = {
  href: "",
  onclick: "return false",
  class: ""
}
const _hoisted_5 = { class: "after-button status-select-teetime" }
const _hoisted_6 = {
  href: "",
  onclick: "return false"
}
const _hoisted_7 = { class: "after-button status-payment" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  href: "",
  onclick: "return false"
}
const _hoisted_11 = { class: "status-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ez_icon = _resolveComponent("ez-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("button", _hoisted_5, [
            _createVNode(_component_ez_icon, {
              icon: "ezGolf-icon-golf-course",
              size: 18,
              style: {"margin-right":"5px"}
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('bo_step_select_tee_time')), 1)
          ])
        ]),
        _createElementVNode("a", _hoisted_6, [
          _createElementVNode("button", _hoisted_7, [
            _createVNode(_component_ez_icon, {
              icon: "ezGolf-icon-wallet",
              size: 18,
              style: {"margin-right":"5px"}
            }),
            ($setup.AllowBoPaymentOnline == 'ON')
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('bo_step_payment')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('bo_step_confirm')), 1))
          ])
        ]),
        _createElementVNode("a", _hoisted_10, [
          _createElementVNode("button", _hoisted_11, [
            _createVNode(_component_ez_icon, {
              icon: "ezGolf-icon-check-circle",
              size: 18,
              style: {"margin-right":"5px"}
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('bo_step_result')), 1)
          ])
        ])
      ])
    ])
  ]))
}