import { openDB, deleteDB } from "idb";
import { Storage, TableStorage } from "@/type/type";

const useIndexDB = (): { storage: Storage } => {
  const DB_NAME = process.env.VUE_APP_DB_NAME ?? "ezGolf";
  const VERSION = process.env.VUE_APP_DB_VERSION
    ? Number(process.env.VUE_APP_DB_VERSION)
    : 1;
  const tables: TableStorage[] = ["cart"];

  const dbPromise = openDB(DB_NAME, VERSION, {
    upgrade(db) {
      tables.forEach((table) => db.createObjectStore(table));
    },
  });

  const initDB = async () => {
    const db = await dbPromise;
    if (db.objectStoreNames.length !== tables.length) {
      deleteDB(DB_NAME);
    }
  };

  initDB();

  const storage: Storage = {
    async setItem<T>(
      table: TableStorage,
      key: string,
      value: T
    ): Promise<boolean> {
      const db = await dbPromise;
      const tx = db.transaction(table, "readwrite");
      tx.objectStore(table).put(JSON.stringify(value), key);
      return true;
    },
    async getItem<T extends { expireAt?: number }>(
      table: TableStorage,
      key: string
    ): Promise<T | undefined> {
      const db = await dbPromise;
      const tx = db.transaction(table);
      let value = await tx.objectStore(table).get(key);
      if (value && typeof value === "string") {
        value = <T>JSON.parse(value);
      }
      if (value?.expireAt < Math.floor(Date.now() / 1000)) {
        return undefined;
      }
      return value;
    },

    async getAll<T>(table: TableStorage): Promise<T[]> {  
      const db = await dbPromise;
      const tx = db.transaction(table);
      const store = tx.objectStore(table);
      const data: T[] = [];
      const getAllKey = await store.getAllKeys();
      for (const key of getAllKey) {
        const value = await store.get(key);
        if (typeof value === "string") {
          data.push(<T>JSON.parse(value));
        } else {
          data.push(value);
        }
      }
      return data;
    },

    async removeItem(table: TableStorage, key: string): Promise<boolean> {
      const db = await dbPromise;
      const tx = db.transaction(table, "readwrite");
      tx.objectStore(table).delete(key);
      return true;
    },
    async clear(table: TableStorage): Promise<boolean> {
      const db = await dbPromise;
      const tx = db.transaction(table, "readwrite");
      tx.objectStore(table).clear();
      return true;
    },
  };

  return {
    storage,
  };
};

export default useIndexDB;
