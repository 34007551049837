import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue3 } from 'bootstrap-vue-3';
// import { createPinia, setActivePinia } from "pinia";
import pinia from '@/pinia/create-pinia';
import '@/styles/style.scss';
import '@/styles/ezIcon/ezICon.scss';
import ezIcon from '@/components/ezIcon/ezIcon.vue'
import Datepicker from '@vuepic/vue-datepicker';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '@vuepic/vue-datepicker/dist/main.css'
import { createI18n } from 'vue-i18n-lite';
import router from './router'
import {cmg_api} from '@/api/cmg_api';
// const {gzip} = require('node-gzip');
// gzip('aaaa').then((res:any) => {
//     console.log(res)
// })
import 'vue3-toastify/dist/index.css';
import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify';
import {EncryptData} from '@/mixin'


if(!localStorage.getItem('Lang')) {
    localStorage.setItem('Lang', '1000000');
}
if(location.pathname.split('/')[1] == '') {
    localStorage.setItem('Lang', '1000000');
} else if(location.pathname.split('/')[1] == 'booking-golf') {
    if(location.pathname.split('/')[2] == '' || !location.pathname.split('/')[2]) {
        localStorage.setItem('Lang', '1000000');
    } else if (location.pathname.split('/')[2].toUpperCase() == 'VI') {
        localStorage.setItem('Lang', '1000000');
    } else if(location.pathname.split('/')[2].toUpperCase() == 'EN') {
        localStorage.setItem('Lang', '1000001');
    } else {
        localStorage.setItem('Lang', '1000000');
    }
}

const url = location.origin
const locale = localStorage.getItem('Lang') || '1000000'

await cmg_api.fetchCMG_getConfig({
    Url: url,
    KeyGroup: 'BO_CONFIG',
    CheckSum: EncryptData([url])
}).then(res => {
    if(res.data.Status == '200') {
        localStorage.setItem('AllowBoPaymentOnline', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "ALLOW_BO_PAYMENT_ONLINE").KeyValue)
        localStorage.setItem('ChainId', res.data.Data.ChainId)
        localStorage.setItem('SiteId', res.data.Data.SiteId)
        localStorage.setItem('TimeZone', res.data.Data.TimeZone)
        localStorage.setItem('ImageFolder', res.data.Data.ImageFolder)
        localStorage.setItem('TimeZoneOffset', res.data.Data.TimeZoneOffset)
        localStorage.setItem('BannerCourse', JSON.stringify(res.data.Data.ImagePathCourse))
        localStorage.setItem('SiteName', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_site_name").KeyValue)
        localStorage.setItem('GolfClassDefault', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_golfclass_default").KeyValue)
        localStorage.setItem('TaDefault', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_ta_default").KeyValue)
        localStorage.setItem('AdvantegedOptionsDefault', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_advanteged_options") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_advanteged_options").KeyValue : null)
        localStorage.setItem('AccessToken', res.data.Data.AccessToken)
        localStorage.setItem('BoPaymentOnlineTerm', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_PAYMENT_ONLINE_TERM").KeyValue)
        localStorage.setItem('BoServiceTerm', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_SERVICE_TERM").KeyValue)
        localStorage.setItem('BoConfigChannelCode', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_CHANNEL_CODE") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_CHANNEL_CODE").KeyValue : null)
        localStorage.setItem('BoConfigSexCode', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_SEX_CODE") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_SEX_CODE").KeyValue : null)
        localStorage.setItem('BoConfigNationnalityCode', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").KeyValue : null)
        localStorage.setItem('BoAdditionalServices', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_ADDITIONAL_SERVICES") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_ADDITIONAL_SERVICES").KeyValue : null)
        localStorage.setItem('BoListBuggyChargeFee', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BUGGY_CHARGE_FEE") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BUGGY_CHARGE_FEE").KeyValue : [])
        localStorage.setItem('BoBookingMinNumberGolfer', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").KeyValue)
        localStorage.setItem('BoBookingMaxNumberGolfer', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").KeyValue)
        localStorage.setItem('BoBookingNumberGolferShowTeeTime', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").KeyValue)
        localStorage.setItem('BoBookingNumberOfGolferMinimumBookingInTeetime', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "NUMBER_OF_GOLFER_MINIMUM_BOOKING_IN_TEETIME").KeyValue)
        localStorage.setItem('BoFooter', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_FOOTER").KeyValue)
        localStorage.setItem('AllowGatewayCharge', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_ALLOW_PAYMENT_GAETWAY_CHARGE_FEE").KeyValue)
        localStorage.setItem('GatewayCharge', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_SERVICE_PAYMENT_GAETWAY_CHARGE_FEE").KeyValue)
        localStorage.setItem('BoPaymentCaddyFee', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BOOKING_PAYMENT_BOOKING_CADDY_FEE") == undefined ? '1' : res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BOOKING_PAYMENT_BOOKING_CADDY_FEE").KeyValue)
        localStorage.setItem('BoPaymentBuggyFee', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BOOKING_PAYMENT_BOOKING_BUGGY_FEE") == undefined ? '1' : res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_BOOKING_PAYMENT_BOOKING_BUGGY_FEE").KeyValue)
    }
})
await cmg_api.fetchCMG_getConfig({
    Url: url,
    KeyGroup: 'EMAIL_CONFIRMATION',
    CheckSum: EncryptData([url])
}).then(res => {
    if(res.data.Status == '200') {
        localStorage.setItem('EmailConfirmBody', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIRM").KeyValue)
    }
})

await cmg_api.fetchCMG_getConfig({
    Url: url,
    KeyGroup: 'EMAIL_ACC_CONFIRMATION',
    CheckSum: EncryptData([url])
}).then(res => {
    if(res.data.Status == '200') {
        localStorage.setItem('EmailConfirmFrom', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "EMAIL_CONFIRMATION").KeyValue) 
        localStorage.setItem('EmailConfirmCc', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_CONFIRM_EMAIL_CC").KeyValue) 
        localStorage.setItem('EmailConfirmBcc', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_CONFIG_CONFIRM_EMAIL_BCC").KeyValue) 
    }
})

await cmg_api.fetchCMG_getConfig({
    Url: url,
    KeyGroup: 'RATE_CONFIG',
    CheckSum: EncryptData([url])
}).then(res => {
    if(res.data.Status == '200') {
        localStorage.setItem('ListAdvantegedOptions', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "RATE_ADVANTEGED_OPTIONS") ? res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "RATE_ADVANTEGED_OPTIONS").KeyValue : [])
    }
})
await cmg_api.fetchCMG_getConfig({
    Url: url,
    KeyGroup: 'BOOKING_CONFIG',
    CheckSum: EncryptData([url])
}).then(res => {
    if(res.data.Status == '200') {
        localStorage.setItem('ZoneIdCaddyChargeFee', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BAR_LOCATION_4_MAIN_LOBBY").KeyValue )
        localStorage.setItem('ZoneIdBuggyChargeFee', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BAR_LOCATION_4_STATER").KeyValue )
        localStorage.setItem('CheckAddtionalFeeFromRate', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BOOKING_GET_ADDITIONAL_FEE_FROM_RATE").KeyValue )
        localStorage.setItem('listWorkingCaddyStatus', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BOOKING_CADDY_STATUS_ALLOW_BOOKING").KeyValue )
        localStorage.setItem('timeToDeleteNoPayBooking', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").KeyValue )
    }
})
const app = createApp(App);
await cmg_api.fetchCMG_getLang({
    Url: url,
    KeyGroup: 'FRONT_END_BO',
    CheckSum: EncryptData([url])
}).then(res => {
    if(res.data.Status == '200') {
        const i18n = createI18n({
            locale,
            fallbackLocale: locale,
            messages: JSON.parse(res.data.Data)
        })
        app.use(i18n)
    }
})
// document.querySelectorAll('.loading')[0].classList.remove('loading')
// document.querySelectorAll('.loading')[0].remove()
document.getElementById('loading-bg')?.remove()
app.use(Vue3Toasity,
    {
      autoClose: 3000,
      // ...
    } as ToastContainerOptions,)
app.use(pinia);
app.use(router);
app.use(BootstrapVue3);
app.component('DatepickerPlugin', Datepicker);
app.component('ez-icon', ezIcon);
app.component('vSelect', vSelect);
app.mount("#app");
export default app;
